import type { BeforeRequestHook } from 'ky';
import { isServer } from '../../utils';
import { extractProjectIdFromRoute, addProjectHeader } from '../../../projects';
import type { HookGetterArgs } from '../types';
import isMobile from '../../../native/isMobile';

export const getProjectHeaders = ({
  fetchArgs: { data, url },
}: HookGetterArgs): BeforeRequestHook => {
  const projectHeadersHook: BeforeRequestHook = async (request) => {
    if (isServer() && url.startsWith('/')) {
      const { headers: nextHeaders } = await import('next/headers');
      const header = nextHeaders();
      const projectId = extractProjectIdFromRoute(header.get('pathname') ?? '');
      addProjectHeader(request.headers, projectId);
    }

    if (!isServer() && !isMobile()) {
      let projectId = extractProjectIdFromRoute(window.location.pathname);

      if (data && 'projectId' in data && data.projectId) {
        projectId = String(data.projectId);
      }
      addProjectHeader(request.headers, projectId);
    }
  };
  return projectHeadersHook;
};
