'use client';

import React, { createContext, useContext, useMemo } from 'react';
import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { EntityTypeVsAssetClass, Permission } from '../../consts/permissions';
import { permissionsLookupByQueryRequest } from '../../consts/permissions';

export type GlobalAccessControlContextType = Partial<{
  [entityType in EntityTypeVsAssetClass]: Permission[];
}>;

const GlobalAccessControlContext = createContext<GlobalAccessControlContextType | null>(null);

export const useGlobalAccessControl = () => {
  const store = useContext(GlobalAccessControlContext);
  if (!store) {
    throw new Error('Missing AccessControlProvider');
  }
  return store;
};

export function GlobalAccessControlProvider({ children }: { children: React.ReactNode }) {
  const { data, isLoading } = useLookup<
    | {
        response?: {
          objects?: { entityType: EntityTypeVsAssetClass; permissions: Permission[] }[];
        };
      }
    | undefined
  >(permissionsLookupByQueryRequest, { query: { staleTime: Infinity } });

  const permissions = useMemo(() => {
    if (!data?.response?.objects) return {};

    return data.response.objects.reduce<GlobalAccessControlContextType>(
      (acc, { entityType, permissions: entityPermissions }) => ({
        ...acc,
        [entityType]: entityPermissions,
      }),
      {},
    );
  }, [data?.response?.objects]);

  if (isLoading) return null;

  return (
    <GlobalAccessControlContext.Provider value={permissions}>
      {children}
    </GlobalAccessControlContext.Provider>
  );
}
