'use client';

import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- needed here
import { useRouter as useNextRouter, useParams } from 'next/navigation';
import { getAdaptedProjectsRoute } from './project/utils';

const useRouter: typeof useNextRouter = () => {
  const router = useNextRouter();
  const { projectSlug } = useParams<{ projectSlug?: string }>();

  return useMemo(() => {
    return {
      ...router,
      push: (url, options) => router.push(getAdaptedProjectsRoute(url, projectSlug), options),
      replace: (url, options) => router.replace(getAdaptedProjectsRoute(url, projectSlug), options),
    };
  }, [projectSlug, router]);
};

export default useRouter;
