export const REDIRECT_TO_KEY = 'returnTo';
export const SAML_LOGIN_URL = '/auth/sso/begin-login';
export const LOGOUT_URL = '/auth/logout';
export const AUTH_ENDPOINT_KEY = '/auth';
export const AUTH_KEY = '_at';

/* eslint-disable @typescript-eslint/naming-convention -- needed */
export enum HTTP_HEADERS {
  /**
   * Detect device of the user using useragent of nextjs
   */
  Device = 'x-ua-device',

  /**
   * Trace id for the request created by the client to be used for debugging purposes
   */
  TraceId = 'x-ua-trace-id',

  /**
   * Timestamp of the request created by the client to be used for debugging purposes
   */
  Timestamp = 'x-ua-timestamp',

  /**
   * Project id of the request sent from the context for scoping
   */
  ProjectId = 'x-ua-project-id',

  /**
   * App id of the request sent from the context for scoping, used for integration purposes
   */
  AppId = 'x-ua-app',

  /**
   * Tenant domain of the request sent from the context for scoping, used for integration purposes
   */
  TenantDomain = 'x-ua-domain',

  /**
   * Locale of the user used from user api
   */
  Locale = 'x-ua-locale',

  /**
   * When the backend wants the user to update their password, they set this header in response to indicate to redirect to update password page
   */
  ExpiredPassword = 'x-ua-expired-password',

  /**
   * Is the interface public or private
   */
  PUBLIC = 'x-ua-public-interface',
}

export const CONTENT_TYPE_HEADER = 'Content-Type';
