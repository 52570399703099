import { forwardRef, useMemo } from 'react';
import _merge from 'lodash/merge';
import { slowCn } from '../../lib/utils';
import type { TypographyComponentAs, TypographyProps, TypographyVariants } from './types';
import { truncateStyle, typographyVariants } from './styles';

const COMPONENTS: Record<TypographyVariants, TypographyComponentAs> = {
  'display-2xl': 'h1',
  'display-xl': 'h2',
  'display-lg': 'h3',
  'display-md': 'h4',
  'display-sm': 'h5',
  'display-xs': 'h6',
  'text-xl': 'p',
  'text-lg': 'p',
  'text-md': 'p',
  'text-sm': 'p',
  'text-xs': 'p',
  'text-xxs': 'span',
  'text-xxxs': 'span',
  'text-code': 'code',
};

const Typography = forwardRef<HTMLDivElement, React.PropsWithChildren<TypographyProps>>(
  (props, ref) => {
    const {
      variant,
      className,
      children,
      align,
      weight,
      as,
      id,
      color,
      htmlFor,
      dangerouslySetInnerHTML,
      maxNumberOfLines,
      minNumberOfLines,
      style: propStyle,
      ...restProps
    } = props;

    const defaultComponent = variant ? COMPONENTS[variant] : 'span';
    const Component = as ?? defaultComponent;
    const variantClassNames = slowCn(
      typographyVariants({
        variant,
        align,
        weight,
        color,
        className,
      }),
    );

    const style = useMemo(
      () => _merge(truncateStyle({ maxNumberOfLines, minNumberOfLines }), propStyle),
      [minNumberOfLines, maxNumberOfLines, propStyle],
    );

    return (
      <Component
        className={variantClassNames}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        htmlFor={htmlFor}
        id={id}
        ref={ref as never}
        style={style}
        {...restProps}
      >
        {children}
      </Component>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;
