/* eslint-disable @typescript-eslint/prefer-ts-expect-error,@typescript-eslint/ban-ts-comment -- process */
export const fallbackLng = 'en';
export const languages = [fallbackLng, 'de', 'fr', 'fr-FR', 'ar-AE', 'ar'];
export const defaultNS = 'common';
export const cookieName = 'i18next';
// https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // @ts-ignore
    debug: process.env.NODE_ENV === 'development' && typeof window !== 'undefined',
    supportedLngs: languages,
    fallbackLng,
    nonExplicitSupportedLngs: true,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    interpolation: {
      escapeValue: false,
    },
  };
}
